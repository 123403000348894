<table nxTable zebra>
    <thead>
    <tr nxTableRow>
        <th nxHeaderCell>
            {{'FIELD.NAME' | translate}}
        </th>
        <th nxHeaderCell>
            {{'FIELD.ALLOCATION' | translate}}
        </th>
        <th *ngIf="!readonlyMode" nxHeaderCell>

        </th>
    </tr>
    </thead>
    <tbody>
    <tr nxTableRow *ngFor="let beneficiary of rows;let i = index;">
        <ng-container [ngSwitch]="beneficiary.type">
            <td *ngSwitchCase="LegalType.BANK" nxTableCell>{{beneficiary.bankDetails.name}}</td>
            <td *ngSwitchCase="LegalType.PERSON" nxTableCell>
                {{beneficiary.personDetails.firstName}} {{beneficiary.personDetails.lastName}}
            </td>
            <td *ngSwitchCase="LegalType.LEGAL_PERSON" nxTableCell>
                {{beneficiary.legalPersonDetails.name}}
            </td>
        </ng-container>
        <td nxTableCell>{{beneficiary.allocation}} %</td>
        <td nxTableCell *ngIf="!readonlyMode">
            <button nxPlainButton type="button" class="nx-margin-right-m" (click)="actionHandler(TableActionType.EDIT,i)">
                {{'BUTTON.EDIT' | translate}}
            </button>
            <button nxPlainButton type="button" class="nx-margin-right-m" (click)="actionHandler(TableActionType.DELETE,i)">
                {{'BUTTON.DELETE' | translate}}
            </button>
        </td>
    </tr>
    </tbody>
</table>
