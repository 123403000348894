import { NgModule } from '@angular/core';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxNumberStepperModule } from '@aposin/ng-aquila/number-stepper';
import { NxHeaderModule } from '@aposin/ng-aquila/header';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxLabelModule } from '@aposin/ng-aquila/base';
import { NxIconModule } from '@aposin/ng-aquila/icon';

import { SharedModule } from '@shared/shared.module';

import { HeaderComponent, LanguageSelectComponent, VersionComponent } from '@core/components';
import { HttpClientModule } from '@angular/common/http';
import { SessionStorageService } from './services';


const COMPONENTS = [
    HeaderComponent,
    LanguageSelectComponent,
    VersionComponent,
];

@NgModule({
    declarations: [
        ...COMPONENTS,
    ],
    imports: [
        NxHeaderModule,
        NxLinkModule,
        NxButtonModule,
        NxGridModule,
        NxFormfieldModule,
        NxInputModule,
        NxNumberStepperModule,
        NxLabelModule,
        NxHeadlineModule,
        NxIconModule,
        HttpClientModule,

        // fixme Core nie moze zalezeć od Shared
        SharedModule,
    ],
    providers: [
        SessionStorageService,
    ],
    exports: [
        ...COMPONENTS
    ]
})
export class CoreModule {
}
