import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'ed-success-page',
    templateUrl: './success-page.component.html'
})
export class SuccessPageComponent implements OnInit {

    context: string;

    constructor(private activatedRoute: ActivatedRoute) {

    }

    ngOnInit(): void {
        this.context = this.activatedRoute.snapshot.queryParams['context'];
    }

}
