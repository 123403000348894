<div nxRow class="nx-margin-top-5xl">
    <div nxCol="12">
        <h1 nxHeadline="section" class="u-text-center u-text-weight-semibold">{{'SUCCESS_PAGE.HEADER_1' | translate}}</h1>
        <h2 nxHeadline="subsection-large" class="u-text-center">{{'SUCCESS_PAGE.HEADER_2' | translate}}</h2>

        <div *ngIf="context" nxRow rowJustify="center" class="nx-margin-top-m">
            <div nxCol="11,11,8,5,5">
                <nx-message context="success">
                    {{'ERROR.SEND_DECLARATION_SUCCESS_MESSAGE.' + context | translate}}
                </nx-message>
            </div>
        </div>
        
    </div>
</div>
